import { Link } from "react-router-dom";

const Homepage = () => {
  return (
    <div className=" w-full h-full bg-black">
      <div className=" flex justify-center items-center">
        <img src="/assets/logo.png" width={"100px"} className=" mt-[50px]" />
      </div>

      <div className=" mt-[50px] p-5 flex flex-col gap-3">
        <p className=" text-[18px] not-italic font-semibold leading-[130%] tracking-[-0.36px] text-white">
          dropp
        </p>
        <Link to={"https://dropp-phygital.com/dashboard/c"}>
          <div className=" group flex justify-around items-center rounded-[20px] border-[2px] border-[#262626] p-4 cursor-pointer hover:bg-[#626467]">
            <div className=" flex justify-start items-center gap-2 flex-1">
              <div>
                <img src="/assets/avatar/dropphygital.png"></img>
              </div>
              <div className=" text-white">droppphygital</div>
            </div>
            <div className=" w-[30px]">
              <img
                src="/assets/svg/arrow-right.svg"
                className=" group-hover:ml-2 duration-150"
              ></img>
            </div>
          </div>
        </Link>

        <Link to={"https://droppverse.com"}>
          <div className=" group flex justify-around items-center rounded-[20px] border-[2px] border-[#262626] p-4 cursor-pointer hover:bg-[#626467]">
            <div className=" flex justify-start items-center gap-2 flex-1">
              <div>
                <img src="/assets/avatar/dropverse.png"></img>
              </div>
              <div className=" text-white">droppverse</div>
            </div>
            <div className=" w-[30px]">
              <img
                src="/assets/svg/arrow-right.svg"
                className=" group-hover:ml-2 duration-150"
              ></img>
            </div>
          </div>
        </Link>

        <p className=" text-[18px] not-italic font-semibold leading-[130%] tracking-[-0.36px] text-white">
          MOC
        </p>
        <Link to={"https://cup.moc.gov.sa/Game/index.html"}>
          <div className=" group flex justify-around items-center rounded-[20px] border-[2px] border-[#262626] p-4 cursor-pointer hover:bg-[#626467]">
            <div className=" flex justify-start items-center gap-2 flex-1">
              <div>
                <img src="/assets/avatar/culture.png"></img>
              </div>
              <div className=" text-white">Cultural Universe Metaverse</div>
            </div>
            <div className=" w-[30px]">
              <img
                src="/assets/svg/arrow-right.svg"
                className=" group-hover:ml-2 duration-150"
              ></img>
            </div>
          </div>
        </Link>

        <p className=" text-[18px] not-italic font-semibold leading-[130%] tracking-[-0.36px] text-white">
          Aramco
        </p>
        <div className=" group flex justify-around items-center rounded-[20px] border-[2px] border-[#262626] p-4 cursor-pointer hover:bg-[#626467]">
          <div className=" flex justify-start items-center gap-2 flex-1">
            <div>
              <img src="/assets/avatar/aramco.png"></img>
            </div>
            <div className=" text-white">Aramco Test Flight</div>
          </div>
          <div className=" w-[30px]">
            <img
              src="/assets/svg/arrow-right.svg"
              className=" group-hover:ml-2 duration-150"
            ></img>
          </div>
        </div>

        <div className=" mt-10 flex justify-center items-center">
          <img src="/assets/footer/italo_zanzi.png"></img>
        </div>
      </div>
    </div>
  );
};

export default Homepage;
