import "./App.css";

import {
  BrowserRouter,
  Route,
  RouterProvider,
  Routes,
  useLocation,
} from "react-router-dom";

import Homepage from "./pages/homepage";
import Homepage1 from "./pages/homepage1";

function App() {
  function RouterComponent() {
    return (
      <div>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/christopher" element={<Homepage1 />} />
        </Routes>
      </div>
    );
  }

  return (
    <BrowserRouter>
      <RouterComponent />
    </BrowserRouter>
  );
}

export default App;
